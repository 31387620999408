import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import axios from "services/api/client";

import { ERROR_MESSAGES, REQUEST_STATUS } from "./useRequest.constants";
import {
  DataType,
  IUseRequestParams,
  TApiRequestMetod,
  TStatuses,
} from "./useRequest.types";
import { TSetState } from "constants/types";
import browserStorage from "services/storage/browserStorage";

export const useRequest = <
  TResponse extends any = any,
  TBody = any,
  TUpdateBody = any
>(
  defaultValue: any = undefined,
  options: IUseRequestParams = {
    isAutoSetInitial: false,
    setToInitialWhenError: false,
    successToast: true,
  }
): [
  {
    get: (url: string) => Promise<any>;
    post: (url: string, data: TBody) => Promise<any>;
    put: (url: string, data: TUpdateBody | TBody) => Promise<any>;
    deleteRequest: (url: string, data?: any) => Promise<any>;
  },
  DataType<TResponse>, // ! DataType<TResponse | undefined>
  TStatuses,
  any,
  {
    setStatus: TSetState<TStatuses>;
  }
] => {
  const { isAutoSetInitial, setToInitialWhenError, successToast } = options;
  const navigate = useNavigate();
  const [data, setData] = useState<DataType<TResponse>>({
    code: 0,
    data: defaultValue || undefined || 0 || {},
    message: "no error",
  });

  const [status, setStatus] = useState<TStatuses>(REQUEST_STATUS.initial);
  const [error, setError] = useState<any>();
  const language = localStorage.getItem("i18nextLng") || "uz";

  const get = async (url: string) => await sendRequest("get", url);

  const post = async (url: string, data: TBody) =>
    await sendRequest("post", url, data);

  const put = async (url: string, data: TBody | TUpdateBody) =>
    await sendRequest("put", url, data);

  const deleteRequest = async (url: string, data: any) =>
    await sendRequest("delete", url);

  const sendRequest = async (
    method: TApiRequestMetod,
    url: string,
    data?: any
  ) => {
    setStatus(REQUEST_STATUS.loading);
    try {
      const res = await axios[method](encodeURI(url.trim()), data);
      if (res.status === 13000) {
        // debugger;
        navigate("/notFound");
        return;
      }
      if (res.status === 401) {
        // debugger;
        navigate("/login");
      }
      setData(res.data);
      setStatus(REQUEST_STATUS.success);
      if (isAutoSetInitial) {
        setTimeout(() => {
          setStatus("INITIAL");
        }, 1000);
      }
      if (method !== "get" && successToast) {
        if (
          res.data &&
          url !== "tariffs/price" &&
          url !== "order" &&
          url !== "message"
        ) {
          toast.success(res?.data?.message);
        }
      }

      return res.data;
    } catch (err: any) {
      const isAgentNotFound = browserStorage.get("agentNotFound");

      if (err?.response?.status === 13000) {
        // debugger;
        navigate("/notFound");
        return;
      }
      if (err?.response?.status === 401 && !isAgentNotFound) {
        // debugger;
        return navigate("/login");
      }
      setError(err);
      setStatus(REQUEST_STATUS.failed);
      if (err?.response) {
        if (ERROR_MESSAGES[language][err.response.data.code]) {
          return toast.error(ERROR_MESSAGES[language][err.response.data.code]);
        }
        if(!url.includes("user?phoneNumber")) toast.error(err.response?.data?.message);
      } else {
        toast.error("Error");
      }
      if (setToInitialWhenError) {
        setData(defaultValue);
      }
    }
  };
  const methods = {
    get,
    post,
    put,
    deleteRequest,
  };
  return [
    methods,
    data,
    status,
    error,
    {
      setStatus,
    },
  ];
};
