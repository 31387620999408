import { ConfigProvider, TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import Error from "../Error/Error";
import { RangeTimePickerStyled } from "./RangeTimePicker.styled";
import IMask from "imask";
import Label from "../Label/Label";
import { KeyboardEvent } from "react";

dayjs.extend(customParseFormat);
type TTimeFormats = "HH:mm:ss" | "HH:mm";

const GET_MASKED = (format: TTimeFormats) =>
  IMask.createMask({
    blocks: {
      HH: { from: 0, mask: IMask.MaskedRange, to: 23 },
      mm: { from: 0, mask: IMask.MaskedRange, to: 59 },
      ss: { from: 0, mask: IMask.MaskedRange, to: 59 },
    },
    format: (date: Date) => dayjs(date).format(format),
    mask: Date,
    parse: (date: string) => dayjs(date, format),
    pattern: format,
    autofix: true,
  });

interface IRangeTimeProps<TFormType extends FieldValues> {
  control: Control<TFormType>;
  name: FieldPath<TFormType>;
  // seconds?: boolean;
  // register: /* (props: UseFormRegister<TFormType>) => */ UseFormRegisterReturn;
  rules?: Omit<
    RegisterOptions<TFormType, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  label?: string;
  format?: TTimeFormats;
}

function RangeTimePicker<TFormType extends FieldValues>({
  rules = {
    required: false,
  },
  control,
  name,
  label,
  format = "HH:mm:ss",
}: // seconds = false,
IRangeTimeProps<TFormType>) {
  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ fieldState, field }) => (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#4ce5b1",
            },
          }}
        >
          {label && (
            <Label label={label /*  + `${!!params?.ref ? "*" : ""}` */} />
          )}
          <RangeTimePickerStyled>
            <TimePicker.RangePicker
              // defaultValue={[
              //   dayjs("12:00", "HH:mm"),
              //   dayjs("12:00", "HH:mm").add(1, "hour"),
              // ]}
              status={!!fieldState.error ? "error" : undefined}
              placeholder={[
                dayjs().subtract(2, "hour").format(format),
                dayjs().format(format),
              ]}
              format={format}
              onKeyDown={(event: KeyboardEvent<HTMLElement>) => {
                const input = event.target as HTMLInputElement;
                input.value = GET_MASKED(format).resolve(input.value);
              }}
              {...field}
            />
            <>
              {!!fieldState.error && (
                <Error
                  message={fieldState?.error?.message || "error in time picker"}
                />
              )}
            </>
          </RangeTimePickerStyled>
        </ConfigProvider>
      )}
    />
  );
}

export default RangeTimePicker;
