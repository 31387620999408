import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

import { REQUEST_STATUS } from "hooks";

import { SaveButtonStyled } from "./Button.style";
import { SaveButtonType } from "./Button.types";

const Button: FC<SaveButtonType> = ({
  onClick,
  type,
  value,
  className,
  symbol = false,
  loading,
  disabled,
  formId,
  color = "success",
  ...props}) => {
  const { t } = useTranslation();
  return (
    <SaveButtonStyled
    className={className}
    form={formId}
    variant="contained"
    color={color}
    disabled={loading === REQUEST_STATUS.loading || disabled}
    onClick={onClick}
    type={type}
    {...props}
    >
      {symbol && <> &#43; </>}
      {value || t("components.button.save")}
      {loading === REQUEST_STATUS.loading && (
        <CircularProgress className="loading_animation" size="1.8rem" />
      )}
    </SaveButtonStyled>
  );
};

export default Button;
